<template>
  <ig-page-base
    v-if="showPage"
    :loading="loading"
    :is-boxed="true"
    :title="isBackOffice ? $t('pageLogin.titleBackOffice') : $t('pageLogin.title')"
    class="page-login">
    <ig-form-schema
      :schema="customSchema"
      @submit="onFormSubmit">
      <template slot="actions">
        <div class="field page-login__field--forgot" v-if="!isBackOffice">
          <p class="has-text-centered">
            <router-link
              :to="{ name: 'page-forgot-password' }"
              class="button is-text is-small">
              {{ $t('pageLogin.form.forgotPassword') }}
            </router-link>
          </p>
        </div>

        <div class="field">
          <b-button
            class="is-uppercase page-login__buttons"
            type="is-primary"
            native-type="submit"
            expanded>
            {{ $t('pageLogin.form.submit') }}
          </b-button>
        </div>
      </template>
    </ig-form-schema>

    <div class="field" v-if="!isBackOffice">
      <p class="is-size-7 has-text-centered">
        {{ $t('pageLogin.form.createAccount') }}
        <a
          :href="registerLink"
          class="button is-text is-link is-primary has-text-weight-bold">
          {{ $t('pageLogin.form.createAccountLink') }}
        </a>
      </p>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase, IgFormSchema, Auth } from '@integrai/ui';

const { mapState, mapActions } = createNamespacedHelpers('login');

export default Vue.extend({
  name: 'page-login',
  data() {
    return {
      showPage: false,
    };
  },
  components: {
    IgPageBase,
    IgFormSchema,
  },
  computed: {
    ...mapState([
      'loading',
    ]),
    customSchema() {
      return {
        fields: [
          {
            name: 'email',
            component: 'input',
            fieldAttrs: {
              label: this.$t('pageLogin.form.email.label'),
            },
            attrs: {
              placeholder: this.$t('pageLogin.form.email.placeholder'),
              'icon-pack': 'fas',
              icon: 'envelope',
            },
            validation: {
              name: this.$t('pageLogin.form.email.label'),
              rules: 'required|email',
            },
          },
          {
            name: 'password',
            component: 'input',
            fieldAttrs: {
              label: this.$t('pageLogin.form.password.label'),
            },
            attrs: {
              placeholder: this.$t('pageLogin.form.password.placeholder'),
              type: 'password',
              'password-reveal': true,
              'icon-pack': 'fas',
              icon: 'key',
            },
            validation: {
              name: this.$t('pageLogin.form.password.label'),
              rules: 'required',
            },
          },
        ],
      };
    },
    isBackOffice() {
      const { app } = this.$route.query;
      return app === 'backoffice';
    },
    isSupport() {
      const { support } = this.$route.query;
      return support;
    },
    registerLink() {
      const { return: returnUrl } = this.$route.query;

      if (returnUrl) {
        return `${process.env.VUE_APP_MANAGE_URL}/auth/register?return=${returnUrl}`;
      }

      return `${process.env.VUE_APP_MANAGE_URL}/auth/register`;
    },
  },
  methods: {
    ...mapActions([
      'login',
      'loginBackOffice',
    ]),
    onFormSubmit(form) {
      const promise = this.isBackOffice ? this.loginBackOffice(form) : this.login(form);

      return promise
        .then(() => this.redirect());
    },
    redirect() {
      const { return: returnUrl } = this.$route.query;
      const {
        VUE_APP_MANAGE_URL,
        VUE_APP_BACK_OFFICE_URL,
        VUE_APP_SUPPORT_URL,
      } = process.env;

      let redirectUrl = VUE_APP_MANAGE_URL;

      if (this.isBackOffice) {
        redirectUrl = VUE_APP_BACK_OFFICE_URL;
      }

      if (this.isSupport) {
        const user = Auth.getUser();
        redirectUrl = `${VUE_APP_SUPPORT_URL}/login?jwt=${user.supportToken}`;
      }

      window.location.href = returnUrl || redirectUrl;
    },
  },
  beforeMount() {
    if (Auth.isAuth()) {
      this.redirect();
    } else {
      this.showPage = true;
    }
  },
});
</script>
<style lang="scss">
.page-login {
  width: 100%;

  &__field {
    &--forgot {
      &.field:not(:last-child) {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;

        .button {
          font-size: 0.75rem;
        }
      }
    }
  }

  &__buttons {
    margin-bottom: 1rem;
    font-weight: bold;
  }
}

.field .button.is-text {
  line-height: 1.2;
}
</style>
